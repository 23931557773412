import { render, staticRenderFns } from "./SistemaMantListItem.vue?vue&type=template&id=3a0db954&scoped=true&"
import script from "./SistemaMantListItem.vue?vue&type=script&lang=js&"
export * from "./SistemaMantListItem.vue?vue&type=script&lang=js&"
import style0 from "./SistemaMantListItem.vue?vue&type=style&index=0&id=3a0db954&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a0db954",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VAvatar,VBtn,VChip,VIcon,VListItemAvatar,VListItemContent,VListItemIcon,VListItemSubtitle,VListItemTitle})
