<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ formattedItem.title }}
      </v-list-item-title>
      <v-list-item-subtitle
        class="break-word"
      >
        {{ formattedItem.subtitle }}
      </v-list-item-subtitle>
      <v-list-item-subtitle
        v-if="formattedItem.caducada"
      >
        <v-chip
          label
          color="error"
          dark
          small
          class="ml-1"
        >
          Caducada
        </v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-icon>
      <v-btn
        v-if="formattedItem.permiteGenerarRevision"
        icon
        @click.stop="clickGenerarRevision"
      >
        <v-icon>{{ $vuetify.icons.values.revision }}</v-icon>
      </v-btn>
    </v-list-item-icon>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { stringToHslColor } from '@/utils/ui'
import { calculateMd5 } from '@/utils/crypto'
import { nonEmpty } from '@/utils/templates'
import { currentDate } from '@/utils/date'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    hasPermCrearParte: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = this.$options.filters.linebreaksBr(nonEmpty`${item.tactuacion_descripcion} ${item.periodo_revision_descripcion}`)
      const fultRevision = this.$options.filters.shortDate(item.fultrevision)
      const fproxRevision = this.$options.filters.shortDate(item.fproxrevision)
      const fplanificacion = this.$options.filters.shortDate(item.fplanificacion)
      item.subtitle = nonEmpty`
        Última ${fultRevision} - Próxima ${fproxRevision} - Planificada ${fplanificacion}
      `
      item.avatar = this.$options.filters.initials(item.periodo_revision_descripcion)
      item.avatarColor = stringToHslColor(calculateMd5(item.periodo_revision_descripcion))
      item.caducada = item.fproxrevision < currentDate()
      item.permiteGenerarRevision = false
      if (this.hasPermCrearParte) {
        item.permiteGenerarRevision = item.permite_planificar_revision || item.con_ot_pendiente === 0
      }
      return item
    }
  },
  methods: {
    clickGenerarRevision () {
      this.$emit('click-generar-revision', { item: this.item, index: this.index })
    },
  },
}
</script>

<style scoped>
.break-word {
  word-break: break-word;
  white-space: normal;
}
</style>
